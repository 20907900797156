import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import App from './App.vue';
import HomePage from './components/HomePage.vue';

import './assets/styles/base.scss';

Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.config.productionTip = false;

//import messagesHR from './assets/i18n/hr';
//import messagesHU from './assets/i18n/hu';
import messagesRS from './assets/i18n/rs';
//import messagesSL from './assets/i18n/sl';

const i18nMessages = {
  //hr: messagesHR,
  //hu: messagesHU,
  rs: messagesRS,
  //sl: messagesSL,
};

// If language is specified in the URL, use it
let locale = window.location.pathname.split('/')[1];

// Else, use browser language
if (!locale) {
  locale = window.navigator.language;
  if (locale.includes('-')) {
    locale = locale.split('-')[0].toLowerCase();
  }
}

// If locale is not among supported languages, use default
if (!Object.keys(i18nMessages).includes(locale)) {
  locale = 'rs';
}

const i18n = new VueI18n({
  locale,
  fallbackLocale: 'rs',
  messages: i18nMessages,
});

// Define routes for different languages
const routes = [
  //{ path: '/hr', component: HomePage },
  //{ path: '/hu', component: HomePage },
  { path: '/rs', component: HomePage },
  //{ path: '/sl', component: HomePage },
  { path: '/', redirect: `/${locale}` }, // Redirect root path to detected language
  {
    path: '*',
    redirect: (to) => {
      if (to.path.startsWith('/pdf/')) {
        return null; // Exclude "/pdf/*" routes from redirect
      } else {
        return '/'; // Redirect invalid paths to root
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  // Set the HTML lang attribute to the current language
  document.documentElement.setAttribute('lang', locale);

  next();
});

new Vue({
  render: (h) => h(App),
  i18n,
  router,
}).$mount('#app');
