<template>
  <div id="app">


    <router-view></router-view>
    <footer-bottom></footer-bottom>
  </div>
</template>

<script>
import FooterBottom from './components/FooterBottom.vue';

export default {
  name: 'App',

  components: {
    FooterBottom,
    
  },

  created() {
    this.addAnalytics();
  },

  methods: {
    async addAnalytics() {
      await this.addScriptToHead('https://cc.dev.agencija101.si/cc.js');
      this.addScriptToHeadByText(`
      a101cc.cookieStart("GTM-MNL7CZ3",
      ${this.$t('message.cookieConsentData')}
      );
      `);
    },
    async addScriptToHead(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.setAttribute('src', src);
        script.onload = () => {
          resolve();
        };
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    addScriptToHeadByText(text) {
      const script = document.createElement('script');
      script.text = text;
      document.head.appendChild(script);
    },
  },
};
</script>

<style></style>
