<template>
  <div class="dialog">
    <div class="dialog__backdrop" :data-opened="opened"></div>

    <div class="dialog__content">
      <Transition appear name="dialog-content">
        <TermsAndConditions v-if="which === 'tnc'" @close="close"></TermsAndConditions>

        <ClaimPrize v-if="which === 'claim'" @close="close"></ClaimPrize>

        <Wins v-if="which === 'wins'" @close="close" @goToClaim="onClaim"></Wins>

        <Loses v-if="which === 'loses'" @close="close"></Loses>
      </Transition>
    </div>
  </div>
</template>

<script>
import ClaimPrize from './ClaimPrize';
import Loses from './Loses';
import TermsAndConditions from './TermsAndConditions';
import Wins from './Wins';

export default {
  name: 'Dialog',
  data: function () {
    return {
      opened: false,
      which: undefined
    };
  },
  methods: {
    open(which) {
      this.opened = true;
      this.which = which;

      document.body.style.overflow = 'hidden';
      this.$el.style.pointerEvents = 'auto';
    },
    close() {
      this.opened = false;
      this.which = undefined;

      document.body.style.overflow = null;
      this.$el.style.pointerEvents = 'none';
    },
    onClaim() {
      this.close();
      setTimeout(() => {
        this.$emit('goToClaim');
      }, 700);
    }
  },
  components: {
    TermsAndConditions,
    ClaimPrize,
    Wins,
    Loses
  }
};
</script>

<style scoped lang="scss">
@import './../assets/styles/transitions';
@import './../assets/styles/viewports';
@import './../assets/styles/variables';

.dialog {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  pointer-events: none;
  z-index: 20;

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    background-color: $cyan;

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background-color: transparent;
      -webkit-backdrop-filter: blur(0.25rem);
      backdrop-filter: blur(0.25rem);

      @include DESKTOP_VIEWPORT {
        -webkit-backdrop-filter: blur(1rem);
        backdrop-filter: blur(1rem);
      }
    }

    &[data-opened='true'] {
      opacity: 1;
    }
  }

  &__content {
    position: relative;
    z-index: 21;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    pointer-events: none;
  }
}
</style>
