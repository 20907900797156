<template>
  <div class="tnc">
    <div class="tnc__topbar">
      <button class="tnc__topbar--close" @click="$emit('close')">
        <img src="./../assets/images/close.svg" alt="close" />
      </button>
    </div>

    <div class="tnc__dots"></div>

    <div class="wrap">
      <h1 class="tnc__title">{{ $t('message.tncTitle') }}</h1>

      <div class="tnc__text">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sit amet turpis vel sapien faucibus elementum.
          Nulla nec elit volutpat metus luctus lacinia. Morbi libero dui, aliquam a turpis a, hendrerit commodo risus.
          Donec lectus lectus, faucibus non commodo sed, molestie sit amet lacus. Praesent a elit sit amet magna
          tincidunt ornare nec ut elit. Aenean egestas vel turpis at tempor.
        </p>

        <ul>
          <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
          <li>Nulla volutpat leo at dolor bibendum, vel tempus ante laoreet.</li>
          <li>Nulla id purus ut quam dapibus egestas eu blandit quam.</li>
          <li>Sed vitae ipsum vel neque sollicitudin tempor.</li>
          <li>Nulla tempus urna ut ligula consectetur aliquet.</li>
          <li>Maecenas imperdiet magna eget facilisis interdum.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions',
  emits: ['close']
};
</script>

<style scoped lang="scss">
@import './../assets/styles/viewports';
@import './../assets/styles/variables';

.tnc {
  position: relative;
  width: 90%;
  max-width: 319px;

  height: 70%;
  max-height: 686px;

  padding: 19px 0 50px 0;
  overflow-y: auto;

  background-color: $orange;
  border-radius: 20px;

  pointer-events: all;

  @include TABLET_VIEWPORT {
    max-width: 500px;
    max-height: 500px;
    height: 40%;
  }

  @include DESKTOP_VIEWPORT {
    width: 780px;
    max-width: unset;

    height: 394px;
  }

  &__topbar {
    display: flex;
    justify-content: flex-end;
    padding: 0 21px;

    &--close {
      background-color: transparent;
      border: none;
      outline: none;

      &:hover {
        opacity: 0.61;
      }
    }
  }

  &__dots {
    max-width: 319px;
    height: 60px;
    margin: 0 auto;

    background-image: url('./../assets/images/dots.svg');

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .wrap {
    position: absolute;
    width: 100%;
    height: calc(100% - 19px - 31px - 60px);

    overflow-y: auto;
  }

  &__title {
    font-size: 25px;
    line-height: 31px;
    font-weight: 500;
    text-align: center;

    color: #3a3a3a;

    margin: 0 0 40px 0;
  }

  &__text {
    width: 90%;
    margin: 0 auto;

    font-size: 14px;
    line-height: 19px;
    font-weight: normal;
    text-align: center;
    color: white;

    @include DESKTOP_VIEWPORT {
      text-align: left;
    }

    p {
      margin: 0 0 19px 0;
    }

    ul {
      display: block;
      width: 90%;
      padding: 0;
      margin: 0 auto 19px auto;

      @include DESKTOP_VIEWPORT {
        margin-left: 1rem;
      }
    }
  }
}
</style>
