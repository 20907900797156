export default {
  message: {
    title: 'OGREBI DIGITALNU CONTINENTAL SREĆKU. AKO DOBIJEŠ 3 ISTA SIMBOLA, ULAZNICA JE TVOJA!',

   

    description:
      'Continental te <span class="bold">u subotu, 18. 5. 2024,</span> vodi na jedinstveno <span class="bold">drive-in bioskopsko iskustvo!</span>\n' +
      '        <br/>\n' +
      '        <br/>\n' +
      '        Uz malo sreće osvoji <span class="bold">besplatnu ulaznicu</span> za\n' +
      '        <span class="bold">jedan&nbsp;automobil</span> (do četiri osobe) i u dobrom društvu pogledaj svetski\n' +
      '        filmski hit <span class="bold">Race for Glory: Audi vs Lancia.</span>\n' +
      '        <br/>\n' +
      '        <br/>',
    agreementLabel: 'Učešćem u nagradnoj igri, slažete se s',
    termsAndConditions: 'pravilima i uslovima.',
    termsUrl: '/pdf/terms_and_cond.pdf',
    congrats: 'ČESTITKE!',
    damn: 'ŽAO NAM JE!',
    ticketIsYours: 'Ulaznica za gledanje filma <br class="br"> Race for Glory: Audi vs Lancia je tvoja!',
    spamWarning: 'Ako niste primili e-poštu za kartu, proverite svoju neželjenu poštu.',
    betterLuckNextTime:
      'Ulaznica za besplatno gledanje filma ti je izmakla! Svoju sreću možeš ponovno okušati sutra.',
    claimPrize: 'Želim da preuzmem nagradu',
    tncTitle: 'PRAVILA I USLOVI',
    firstName: 'Ime',
    lastName: 'Prezime',
    address: 'Adresa',
    city: 'Grad',
    zipCode: 'Poštanski broj',
    email: 'E-mail',
    sendData: 'POŠALJI PODATKE',
    infoTicketImg: '',
    cookieConsentData: `{
      "bannerText":"Kolačiće koristimo radi poboljšanja vašeg korisničkog iskustva prilikom pregledanja naše web stranice, za analizu poseta naše web stranice i za uvid u to odakle dolaze naši gosti. Za više informacija pogledajte <a href='/pdf/cookies-rs.pdf' target='_blank'>politiku kolačića</a>.",
      "acceptAllBtnText":"Prihvatam sve",
      "managePopupBtnText":"Upravljaj postavke",
      "modal":{
        "firstText":"Radi poboljšanja korisničkog iskustva, postavljamo kolačiće i pristupamo neosetljivim informacijama na vašem uređaju, koristeći ih za poboljšanje naših proizvoda i prilagođavanje oglasa i ostalog sadržaja na ovoj web stranici. Možete prihvatiti sve ili neke od ovih aktivnosti.",
        "mainTitle":"Vrste kolačića",
        "subTitleText":"",
        "necessary":"Neophodni kolačići",
        "necessaryDescription":"Neophodni kolačići čine web stranicu funkcionalnom tako što omogućavaju osnovne funkcije poput navigacije kroz stranicu i pristupa sigurnim područjima web stranice, odabira jezika, fontova i slično. Web stranica neće pravilno funkcionisati bez ovih kolačića, pa ih ne možete isključiti.",
        "analytical":"Analitički kolačići",
        "analyticalDescription":"Analitički kolačići omogućavaju praćenje poseta web stranici kako bi vlasnik web stranice mogao poboljšati web stranicu i njen sadržaj. Ako isključite ove kolačiće, stranica će normalno funkcionisati, ali kao provajder stranice bićemo uskraćeni za informacije koje nam pomažu da unapredimo rad naše stranice i time vaše korisničko iskustvo kod nas.",
        "checkboxTxt":"Slažem se",
        "saveBtnText":"Sačuvaj"
      }
  }`,
    footer: {
      privacyPolicy: 'Politika zaštite ličnih podataka',
      privacyPolicyUrl: '/pdf/privacy-policy-rs.pdf',
      cookiePolicy: 'Politika kolačića',
      cookiePolicyUrl: '/pdf/cookies-rs.pdf',
      cookieManage: 'Podešavanja kolačića',
    },
  },
};
