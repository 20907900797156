<template>
  <form @submit.prevent="onSubmit" class="claim" ref="form">
    <div class="claim__dots"></div>

    <div class="claim__content">
      <div class="claim__content--field des-add-mr">
        <input
          type="text"
          name="firstName"
          id="firstName"
          v-model="firstName"
          @input="onFieldInput('firstName')"
          @focusout="onFieldTouched('firstName')"
          :class="{ invalid: formFieldControls['firstName'].invalid, touched: formFieldControls['firstName'].touched }"
          :placeholder="$t('message.firstName')"
        />
      </div>

      <div class="claim__content--field">
        <input
          type="text"
          name="lastName"
          id="lastName"
          v-model="lastName"
          @input="onFieldInput('lastName')"
          @focusout="onFieldTouched('lastName')"
          :class="{ invalid: formFieldControls['lastName'].invalid, touched: formFieldControls['lastName'].touched }"
          :placeholder="$t('message.lastName')"
        />
      </div>

      <div class="claim__content--field des-fw">
        <input
          type="text"
          name="address"
          id="address"
          v-model="address"
          @input="onFieldInput('address')"
          @focusout="onFieldTouched('address')"
          :class="{ invalid: formFieldControls['address'].invalid, touched: formFieldControls['address'].touched }"
          :placeholder="$t('message.address')"
        />
      </div>

      <div class="claim__content--field des-add-mr">
        <input
          type="text"
          name="city"
          id="city"
          v-model="city"
          @input="onFieldInput('city')"
          @focusout="onFieldTouched('city')"
          :class="{ invalid: formFieldControls['city'].invalid, touched: formFieldControls['city'].touched }"
          :placeholder="$t('message.city')"
        />
      </div>

      <div class="claim__content--field">
        <input
          type="number"
          step="1"
          min="1000"
          max="999999"
          name="zipCode"
          id="zipCode"
          v-model="zipCode"
          @input="onFieldInput('zipCode')"
          @focusout="onFieldTouched('zipCode')"
          :class="{ invalid: formFieldControls['zipCode'].invalid, touched: formFieldControls['zipCode'].touched }"
          :placeholder="$t('message.zipCode')"
        />
      </div>

      <div class="claim__content--field des-fw">
        <input
          type="email"
          name="email"
          id="email"
          v-model="email"
          @input="onFieldInput('email')"
          @focusout="onFieldTouched('email')"
          :class="{ invalid: formFieldControls['email'].invalid, touched: formFieldControls['email'].touched }"
          :placeholder="$t('message.email')"
        />
      </div>

      <div
        v-if="storeResponseMessage !== undefined"
        class="claim__content--result"
        :class="{ success: storeSuccessful, err: !storeSuccessful }"
      >
        <div class="inner">
          {{ storeResponseMessage }}<br>
          <span v-if="storeSuccessful" class="spamWarning" v-html="$t('message.spamWarning')"></span>
        </div>

        <!--<span v-html="$t('message.ticketIsYours')"></span>-->

      </div>

      <div class="claim__content--field des-clear-mb">
        <input
          type="submit"
          :value="$t('message.sendData')"
          :disabled="(!isFormValid || isLoading) && !storeSuccessful"
        />
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ClaimPrize',
  emits: ['close'],
  data: function () {
    return {
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      zipCode: undefined,
      email: '',
      formFieldControls: {
        firstName: {
          touched: false,
          invalid: true,
        },
        lastName: {
          touched: false,
          invalid: true,
        },
        address: {
          touched: false,
          invalid: true,
        },
        city: {
          touched: false,
          invalid: true,
        },
        zipCode: {
          touched: false,
          invalid: true,
        },
        email: {
          touched: false,
          invalid: true,
        },
      },
      isFormValid: false,
      storeSuccessful: false,
      storeResponseMessage: undefined,
      isLoading: false,
    };
  },
  methods: {
    onFieldTouched(name) {
      this.formFieldControls[name].touched = true;
    },
    onFieldInput(name) {
      switch (name) {
        case 'zipCode': {
          this.formFieldControls.zipCode.invalid = !(this.zipCode >= 1000 && this.zipCode <= 999999);
          break;
        }

        case 'email': {
          this.formFieldControls.email.invalid = this.validateEmail(this.email);
          break;
        }

        default: {
          this.formFieldControls[name].invalid = (this[name] || '').trim().length === 0;
          break;
        }
      }

      this.isFormValid = Object.values(this.formFieldControls).every((c) => c.invalid === false);
    },
    async onSubmit() {
      try {
        this.isLoading = true;

        let selectedLocale = this.$i18n.availableLocales.find((al) => al === this.$i18n.locale);
        if (!selectedLocale) selectedLocale = this.$i18n.fallbackLocale;

        let appUrl;
        if (process.env.NODE_ENV === 'production') {
          appUrl = 'https://conti-cinema.dev.agencija101.si/api';
        } else {
          appUrl = 'http://localhost:8000/api';
        }

        const response = await axios.post(`${appUrl}/store-submission`, {
          lang: selectedLocale,
          name: this.firstName,
          last_name: this.lastName,
          address: this.address,
          city: this.city,
          post: this.zipCode,
          email: this.email,
        });

        this.storeSuccessful = response.status === 200;
        this.storeResponseMessage = response.data.message;
        this.isLoading = false;
      } catch (e) {
        this.storeSuccessful = false;
        this.storeResponseMessage = e.response.data.message;
        this.isLoading = false;
      }
    },
    validateEmail(email) {
      const res = String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

      return !res;
    },
  },
};
</script>

<style scoped lang="scss">
@import './../assets/styles/viewports';
@import './../assets/styles/variables';

.spamWarning{
  font-size: 12px;
  color: black;
}

.claim {
  position: relative;
  width: 90%;
  max-width: 319px;

  height: 70%;
  max-height: 686px;

  padding: 19px 0 50px 0;
  overflow-y: auto;

  background-color: $orange;
  border-radius: 20px;

  pointer-events: all;

  @include TABLET_VIEWPORT {
    max-width: 500px;
    max-height: 620px;
    height: 75%;
  }

  @include DESKTOP_VIEWPORT {
    width: 540px;
    height: 476px;
    max-width: unset;
  }

  &__dots {
    max-width: 319px;
    height: 60px;
    margin: 0 auto;

    background-image: url('./../assets/images/dots.svg');

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &__content {
    position: absolute;
    width: 100%;
    height: calc(100% - 19px - 60px - 28px);

    padding-top: 28px;
    overflow-y: auto;

    @include DESKTOP_VIEWPORT {
      position: unset;
      width: 437px;
      height: unset;
      margin: 0 auto;
    }

    &--field {
      max-width: 229px;
      margin: 0 auto 26px auto;

      @include TABLET_VIEWPORT {
        max-width: 425px;
      }

      @include DESKTOP_VIEWPORT {
        display: inline-block;
        width: 46.45%;

        &.des-fw {
          width: 100%;
          max-width: unset;
        }

        &.des-clear-mb {
          margin-bottom: 0;
        }

        &.des-add-mr {
          margin-right: 31px;
        }
      }

      input {
        display: block;
        width: calc(100% - 17px - 17px);
        padding: 17px;

        font-size: 14px;
        color: black;
        font-weight: normal;

        border: 0;
        border-radius: 12px;
        outline: none;

        &::placeholder {
          color: black !important;
        }

        &[type='submit'] {
          width: 100%;

          background-color: black;
          color: white;

          &:hover {
            opacity: 0.78;
            cursor: pointer;
          }

          &[disabled] {
            opacity: 0.25;
          }
        }

        &.invalid.touched {
          outline: 2px solid red;
        }
      }
    }

    &--result {
      text-align: center;
      margin: 0 auto 26px auto;
      padding: 0 25px;

      .inner {
        border-radius: 12px;
        background-color: white;
        padding: 10px 18px;
      }

      &.err {
        color: red;
        font-weight: bold;
      }

      &.success {
        color: green;
        font-weight: bold;
      }
    }
  }
}
</style>
