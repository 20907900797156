import { render, staticRenderFns } from "./Wins.vue?vue&type=template&id=0b699bbc&scoped=true&"
import script from "./Wins.vue?vue&type=script&lang=js&"
export * from "./Wins.vue?vue&type=script&lang=js&"
import style0 from "./Wins.vue?vue&type=style&index=0&id=0b699bbc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b699bbc",
  null
  
)

export default component.exports