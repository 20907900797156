<template>
  <div class="loses">
    <div class="loses__topbar">
      <button class="loses__topbar--close" @click="$emit('close')">
        <img src="./../assets/images/close.svg" alt="close" />
      </button>
    </div>

    <div class="loses__dots"></div>

    <div class="wrap">
      <div class="loses__text">
        <p class="damn-txt" :class="`damn-txt-${$i18n.locale}`">
          <b>
            <span>{{ $t('message.damn') }}</span>
          </b>
        </p>
        <p>
          <span>{{ $t('message.betterLuckNextTime') }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loses',

  emits: ['close'],
};
</script>

<style scoped lang="scss">
@import './../assets/styles/viewports';
@import './../assets/styles/variables';

.loses {
  position: relative;
  width: 90%;
  max-width: 319px;

  height: 70%;
  max-height: 686px;

  padding: 19px 0 50px 0;
  overflow-y: auto;

  background-color: $orange;
  border-radius: 20px;

  pointer-events: all;

  @include TABLET_VIEWPORT {
    max-width: 500px;
    max-height: 500px;
    height: 40%;
  }

  @include DESKTOP_VIEWPORT {
    width: 780px;
    max-width: unset;

    height: 394px;
  }

  &__topbar {
    display: flex;
    justify-content: flex-end;
    padding: 0 21px;

    &--close {
      background-color: transparent;
      border: none;
      outline: none;

      &:hover {
        opacity: 0.61;
      }
    }
  }

  &__dots {
    max-width: 319px;
    height: 60px;
    margin: 0 auto;

    background-image: url('./../assets/images/dots.svg');

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .wrap {
    position: absolute;
    width: 100%;
    // height: calc(100% - 19px - 31px - 60px);
    text-align: center;

    overflow-y: auto;
  }

  .damn-txt {
    margin-bottom: 1em;
    font-size: 26px;
  }

  .claim-btn {
    display: block;
    margin: 33px auto 0 auto;
    padding: 18px 24px;

    font-size: 17px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    background-color: white;
    color: black;
    border: none;
    outline: none;
    border-radius: 12px;

    &:hover {
      opacity: 0.78;
      cursor: pointer;
    }
  }

  &__title {
    font-size: 25px;
    font-weight: 500;
    text-align: center;

    color: #3a3a3a;

    margin: 0 0 40px 0;
  }

  &__text {
    padding: 30px 25px 0;
    font-size: 22px;
    font-weight: normal;
    text-align: center;
    color: black;

    p {
      margin: 0 0 19px 0;
    }

    ul {
      display: block;
      width: 90%;
      padding: 0;
      margin: 0 auto 19px auto;

      @include DESKTOP_VIEWPORT {
        margin-left: 1rem;
      }
    }
  }
}
</style>
