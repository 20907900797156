<template>
  <div class="home-page">
    <div class="des-wrap">

      <!--
<LanguageSwitcher></LanguageSwitcher>
      -->



      <div class="content">
        <div class="home-page__logo"></div>

        <div class="row">
          <div class="col left">
            <h1 class="home-page__title">
              {{ $t('message.title') }}
            </h1>

            <p class="home-page__text" v-html="$t('message.description')"></p>

            <p v-if="selectedLocale === 'hu'" class="home-page__text red underline" v-html="$t('message.hu_postponed')">
            </p>
          </div>
          <div class="col right">
            <div v-if="$t('message.infoTicketImg')">
              <img class="info-ticket" :src="require(`../assets/images/${$t('message.infoTicketImg')}`)" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="home-page__graphic">
        <div class="home-page__graphic--tickets"></div>
        <div class="home-page__graphic--tape"></div>
        <div class="home-page__graphic--popcorn"></div>
      </div>

      <div v-if="fields.length > 0" class="home-page__fields">
        <div class="home-page__fields--row">
          <ScratchableField :field="fields[0]" @revealed="onFieldRevealed(fields[0])" />
          <ScratchableField :field="fields[1]" @revealed="onFieldRevealed(fields[1])" />
        </div>

        <div class="home-page__fields--row">
          <ScratchableField :field="fields[2]" @revealed="onFieldRevealed(fields[2])" />
          <ScratchableField :field="fields[3]" @revealed="onFieldRevealed(fields[3])" />
        </div>

        <div class="home-page__fields--row">
          <ScratchableField :field="fields[4]" @revealed="onFieldRevealed(fields[4])" />
          <ScratchableField :field="fields[5]" @revealed="onFieldRevealed(fields[5])" />
        </div>
      </div>

      <div class="home-page__footer">
        <p class="home-page__footer--text">
          {{ $t('message.agreementLabel') }}
          <br />
          <a class="home-page__footer--text" :href="$t('message.termsUrl')" target="_blank">{{
            $t('message.termsAndConditions')
          }}</a>
        </p>

        <img class="home-page__footer--icon-mob" src="./../assets/images/bottom-graphic.svg" />
      </div>
    </div>

    <Dialog ref="dialog" @goToClaim="onClaimPrize"></Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog';
//import LanguageSwitcher from '@/components/LanguageSwitcher';
import ScratchableField from '@/components/ScratchableField';
import axios from 'axios';

export default {
  name: 'HomePage',

  data: function () {
    return {
      fields: [],
      revealCount: {
        movie: 0,
        popcorn: 0,
        ticket: 0,
      },
      selectedLocale: undefined
    };
  },

  created() {
    this.getStats();
  },

  methods: {
    async getStats() {
      try {
        this.selectedLocale = this.$i18n.availableLocales.find((al) => al === this.$i18n.locale);
        if (!this.selectedLocale) this.selectedLocale = this.$i18n.fallbackLocale;

        let appUrl;

        if (process.env.NODE_ENV === 'production') {
          appUrl = 'https://conti-cinema.dev.agencija101.si/api';
        } else {
          appUrl = 'http://localhost:8000/api';
        }

        console.log(appUrl, axios)



        // TO GET TICKETS FROM API
        const response = await axios.get(`${appUrl}/get-ticket/${this.selectedLocale}`);
        const responseIcons = response.data.icons;

        for (let i = 0; i < responseIcons.length; i++) {
          this.fields.push({
            id: i,
            icon: responseIcons[i],
          });
        }
      } catch (e) {
        console.log(e);
        // tickets without api you need 3 of the SAME pictures to win
        const icons = ['ticket', 'popcorn', 'movie', 'ticket', 'popcorn', 'movie'];



        for (let i = 0; i < icons.length; i++) {
          this.fields.push({
            id: i,
            icon: icons[i],
          });
        }
      }
    },

    onFieldRevealed(field) {
      this.revealCount[field.icon]++;

      if (Object.values(this.revealCount).some((c) => c === 3)) {
        setTimeout(() => {
          this.$refs.dialog.open('wins');
        }, 500);
      } else {
        if (Object.values(this.revealCount).reduce((a, b) => a + b, 0) === 6) {
          setTimeout(() => {
            this.$refs.dialog.open('loses');
          }, 700);
        }
      }
    },

    onClaimPrize() {
      this.$refs.dialog.open('claim');
    },
  },

  components: {
    //LanguageSwitcher,
    ScratchableField,
    Dialog,
  },
};
</script>

<style lang="scss">
@import './../assets/styles/viewports';
@import './../assets/styles/variables';

body {
  margin: 0;
  background-color: $cyan;
  overscroll-behavior: none;
  line-height: 1.33;
}

.home-page {
  background-image: url('./../assets/images/dots-bg.png');
  background-position: center center;
  background-size: cover;
  padding-bottom: 70px;
  overflow-x: hidden;
  min-height: calc(100vh - 70px);

  @include DESKTOP_VIEWPORT {
    overflow: hidden;
    background-size: auto;
  }

  .des-wrap {
    @include DESKTOP_VIEWPORT {
      position: relative;
      width: 100%;
      max-width: 1024px;
      margin: 0 auto;
    }
  }

  &__logo {
    display: block;
    margin: 0 auto 35px;
    width: 80.78%;
    max-width: 315px;
    height: 193px;

    background-image: url('./../assets/images/logo-top.png');
    background-position: center center;
    background-size: 110%;
    background-repeat: no-repeat;

    @include DESKTOP_VIEWPORT {
      width: 477px;
      max-width: unset;
      height: 287px;
      max-height: unset;
      transform: translateX(-73px);
      margin: 0 0 30px 0;
    }
  }

  .content {
    position: relative;
    z-index: 5;
    padding: 0 20px 50px 20px;
    max-width: 815px;
    margin: 0 auto;

    .row {
      @include DESKTOP_VIEWPORT {
        display: flex;
        align-items: flex-end;
      }

      .col.right {
        text-align: center;

        @include DESKTOP_VIEWPORT {
          text-align: left;
        }
      }
    }

    .info-ticket {
      max-width: 350px;
      width: 100%;
      margin: 0 auto 40px;

      @include DESKTOP_VIEWPORT {
        margin: 0 0 40px 60px;
      }
    }
  }

  &__title {
    margin: 0 auto 28px auto;
    max-width: 330px;

    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;

    @include DESKTOP_VIEWPORT {
      width: 363px;
      max-width: unset;

      font-size: 26px;
      text-align: left;
    }
  }

  &__text {
    max-width: 305px;
    margin: 0 auto;
    font-size: 18px;
    text-align: center;

    .bold {
      font-weight: 500;
    }

    &.red {
      color: red !important;
      text-decoration: underline;
    }

    @include DESKTOP_VIEWPORT {
      width: 305px;
      margin: 0;
      text-align: left;
    }
  }

  &__graphic {
    position: relative;

    margin: 0 auto 190px auto;
    width: 87.5%;
    max-width: 342px;
    height: 284px;

    background-image: url('./../assets/images/graphic-center.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

    @include DESKTOP_VIEWPORT {
      position: absolute;
      right: 0;
      top: 0;
      width: 457px;
      max-width: unset;
      height: 380px;
    }

    &--tickets {
      position: absolute;
      top: -65px;
      left: -85px;

      width: 234px;
      height: 170px;

      transform: rotateZ(34deg);

      background-image: url('./../assets/images/graphic-tickets.png');
      background-position: center center;
      background-size: 115%;
      background-repeat: no-repeat;

      @include DESKTOP_VIEWPORT {
        transform: rotateZ(39deg);
        left: -14px;
        top: -145px;
        width: 314px;
        height: 227px;
      }
    }

    &--tape {
      position: absolute;
      right: -100px;
      top: -70px;
      width: 200px;
      height: 194px;

      transform: rotateZ(-17deg);

      background-image: url('./../assets/images/graphic-tape.png');
      background-position: center center;
      background-size: 140%;
      background-repeat: no-repeat;

      @include DESKTOP_VIEWPORT {
        width: 268px;
        height: 260px;

        top: -120px;
        right: -130px;
      }
    }

    &--popcorn {
      position: absolute;
      top: 80px;
      left: -20px;

      width: 493px;
      height: 450px;

      background-image: url('./../assets/images/graphic-popcorn.png');
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;

      transform: rotateZ(-100deg);

      @include DESKTOP_VIEWPORT {
        width: 660px;
        height: 600px;
        top: 120px;
        left: -0px;
      }
    }
  }

  &__fields {
    width: 100%;
    max-width: 288px;
    margin: 0 auto 48px auto;

    display: flex;
    flex-wrap: wrap;

    @include DESKTOP_VIEWPORT {
      width: 874px;
      max-width: unset;
      margin: 0 auto 55px auto;

      flex-direction: row;
      justify-content: space-between;
    }

    &--row {
      display: flex;
      justify-content: space-between;
      width: 100%;

      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0 !important;
      }

      @include DESKTOP_VIEWPORT {
        width: 282px;
        margin-bottom: 0;
      }
    }
  }

  &__result {
    margin: 0 auto 33px auto;

    &.l {
      width: 275px;

      @include DESKTOP_VIEWPORT {
        width: 390px;
      }
    }

    &.w {
      @include DESKTOP_VIEWPORT {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 693px;

        .home-page__result--text {
          width: 320px;

          br {
            display: none;
          }
        }
      }
    }

    &--title {
      font-weight: 500;
      font-size: 21px;

      text-align: center;

      .w {
        color: #dc6f9e !important;
      }

      .l {
        color: #7675b5 !important;
      }
    }

    &--text {
      font-size: 15px;
      text-align: center;
    }

    &--claim {
      display: block;
      margin: 33px auto 0 auto;
      padding: 18px 24px;

      font-size: 17px;
      font-weight: 500;
      text-transform: uppercase;

      background-color: $orange;
      color: black;
      border: none;
      outline: none;
      border-radius: 12px;

      &:hover {
        opacity: 0.78;
        cursor: pointer;
      }

      @include DESKTOP_VIEWPORT {
        margin: 0;
      }
    }
  }

  &__footer {
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;

    @include DESKTOP_VIEWPORT {
      overflow: visible;
    }

    &--text {
      position: relative;
      margin: 0 0 5px 0;
      z-index: 10;

      font-size: 14px;
      font-weight: normal;
      text-align: center;
    }

    &--icon-mob {
      display: none;
      position: absolute;
      left: -70px;
      top: 16px;
      z-index: 1;

      @include DESKTOP_VIEWPORT {
        display: none;
      }
    }
  }
}
</style>
