<template>
  <div class="scratchable-field">
    <div ref="borders" class="scratchable-field__borders">
      <div
        class="scratchable-field__borders--border b1"
        :class="{ revealed: revealed, holding: holding }"
        ref="b1"
      ></div>
      <div
        class="scratchable-field__borders--border b2"
        :class="{ revealed: revealed, holding: holding }"
        ref="b2"
      ></div>
      <div
        class="scratchable-field__borders--border b3"
        :class="{ revealed: revealed, holding: holding }"
        ref="b3"
      ></div>
      <div
        class="scratchable-field__borders--border b4"
        :class="{ revealed: revealed, holding: holding }"
        ref="b4"
      ></div>
    </div>

    <div ref="content" class="scratchable-field__content" :class="field.icon"></div>
  </div>
</template>

<script>
import { ScratchIt } from './../assets/lib/Scratchit.min';

export default {
  name: 'ScratchableField',
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      revealed: false,
      holding: false
    };
  },
  mounted() {
    ScratchIt(
      this.$refs.content,
      '/field-overlay.png',
      '/brush.png',
      this.onRevealed,
      50,
      undefined,
      this.onHold,
      this.onRelease
    );

    this.$refs.content.addEventListener('pointerdown', this.cancelEvent);
    this.$refs.content.addEventListener('pointerup', this.cancelEvent);
    this.$refs.content.addEventListener('pointerleave', this.cancelEvent);
    this.$refs.content.addEventListener('pointermove', this.cancelEvent);
    this.$refs.content.addEventListener('mousedown', this.cancelEvent);
    this.$refs.content.addEventListener('mouseup', this.cancelEvent);
    this.$refs.content.addEventListener('mouseleave', this.cancelEvent);
    this.$refs.content.addEventListener('mousemove', this.cancelEvent);
    this.$refs.content.addEventListener('touchstart', this.cancelEvent);
    this.$refs.content.addEventListener('touchend', this.cancelEvent);
  },
  methods: {
    cancelEvent(event) {
      event.preventDefault();
    },
    onRevealed() {
      this.$refs.b4.style.height = '100%';
      this.revealed = true;

      this.onPercentageChanged(100);

      this.$emit('revealed');
    },
    // This is relative to the threshold that is set
    onPercentageChanged(value) {
      if (value <= 25) {
        this.$refs.b1.style.width = `calc(${(value / 25) * 100}% - 12px)`;
      } else if (value > 25 && value <= 50) {
        this.$refs.b1.style.width = `calc(100% - 12px)`;

        this.$refs.b2.style.height = `${(value / 50) * 100}%`;
      } else if (value > 50 && value <= 75) {
        this.$refs.b1.style.width = `calc(100% - 12px)`;
        this.$refs.b2.style.height = `100%`;

        this.$refs.b3.style.width = `${(value / 75) * 100}%`;
      } else if (value > 75 && value < 100) {
        this.$refs.b1.style.width = `calc(100% - 12px)`;
        this.$refs.b2.style.height = `100%`;
        this.$refs.b3.style.width = `100%`;

        this.$refs.b4.style.height = `${value}%`;
      } else {
        this.$refs.b1.style.width = `calc(100% - 12px)`;
        this.$refs.b2.style.height = `100%`;
        this.$refs.b3.style.width = `100%`;
        this.$refs.b4.style.height = `100%`;
      }
    },
    onHold() {
      this.holding = true;
    },
    onRelease() {
      this.holding = false;
    }
  }
};
</script>

<style scoped lang="scss">
@import './../assets/styles/viewports';
@import './../assets/styles/variables';

.scratchable-field {
  position: relative;
  height: 132px;
  width: 132px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 12px;

  &__borders {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    &--border {
      position: absolute;
      background-color: white;

      transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, border-radius 0.5s ease-in-out;

      &.revealed {
        background-color: $orange !important;
      }

      &.b1 {
        height: 12px;
        left: 12px;
        width: 0;

        transform: scaleY(0);
        opacity: 0;

        &.holding {
          transform: scaleY(1);
          opacity: 1;
          border-top-right-radius: 12px;
        }
      }

      &.b2 {
        right: 0;
        height: 0;
        width: 12px;

        transform: scaleX(0);
        opacity: 0;

        &.holding {
          transform: scaleX(1);
          opacity: 1;

          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }

      &.b3 {
        bottom: 0;
        right: 0;
        height: 12px;
        width: 0;

        transform: scaleY(0);
        opacity: 0;

        &.holding {
          transform: scaleY(1);
          opacity: 1;

          border-bottom-right-radius: 12px;
          border-bottom-left-radius: 12px;
        }
      }

      &.b4 {
        left: 0;
        bottom: 0;
        height: 0;
        width: 12px;

        transform: scaleX(0);
        opacity: 0;

        &.holding {
          transform: scaleX(1);
          opacity: 1;

          border-bottom-left-radius: 12px;
          border-top-left-radius: 12px;
        }
      }
    }
  }

  &__content {
    position: relative;
    z-index: 1;

    width: 124px;
    height: 124px;

    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    border-radius: 12px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);

    &:hover {
      cursor: pointer;
    }

    &.movie {
      background-image: url('./../assets/images/icon-movie.svg');
    }

    &.ticket {
      background-image: url('./../assets/images/icon-ticket.svg');
    }

    &.popcorn {
      background-image: url('./../assets/images/icon-popcorn.svg');
    }
  }
}
</style>
